<template>
    <div>
        <card-header title="Edit Document" icon="fa-pencil"/>

        <card-body>
            <document-form :doc="document"/>

            <card-list v-if="document.file">
                <subheader-list-item title="File" icon="fa-file"/>
                <container-list-item>
                    <data-row :title="document.file_name"><a :href="apiUrl + 'api/users/documents/' + document.file" target="_blank"><i class="fas fa-cloud-download mr-1"></i>Download</a></data-row>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteDocument"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Document?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit" :disabled="!document.subject"><i class="fas fa-check mr-2"></i>Save Document</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {client as http} from "../../http_client";
    import TripNoteForm from "@/cards/trips/NoteForm";
    import DocumentForm from "@/cards/customers/DocumentForm";
    import DataRow from "@/TIER/components/DataRow";

    export default {
        components: {DataRow, DocumentForm, TripNoteForm, ContainerListItem, SubheaderListItem, CardList, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                document: {},
                confirmDelete: false,
                dirty: null,
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/'
            };
        },
        methods: {
            loadDocument: function () {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId + '/documents/' + this.props.docId, {force: true}).then(response => {
                    this.document = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/users/' + this.props.userId + '/documents/' + this.props.docId, {
                    subject: this.document.subject,
                    id_number: this.document.id_number,
                    information: this.document.information,
                    is_app_visible: this.document.is_app_visible
                }, {force: true}).then(response => {
                    this.$reloadCard('customer-documents', {userId: this.props.userId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteDocument() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/users/' + this.props.userId + '/documents/' + this.props.docId).then(response => {
                        this.$reloadCard('customer-documents', {userId: this.props.userId});
                        this.$reloadCard('customer', {userId: this.props.userId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.docId': function () {
                this.dirty = null;
                this.loadDocument();
            },
            'document': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this document? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadDocument();
        }
    };
</script>

<style scoped>

</style>
